/* General Tracker Page Styles */
.tracker-page {
  padding: 20px;
  color: #f1f1f1;
  background-color: #121212;
  font-family: "Poppins", sans-serif;
}

/* Header */
.tracker-header {
  text-align: center;
  margin-bottom: 30px;
}

.tracker-header h1 {
  font-size: 2.5rem;
  color: #f57258;
}

/* Tabs */
.tracker-tabs {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}

.tracker-tab {
  background-color: #f57258;
  color: #fff;
  border: none;
  font-size: 1rem;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s;
}

.tracker-tab:hover {
  background-color: #f45a3d;
  transform: scale(1.05);
}

.tracker-tab.active {
  background-color: #f45a3d;
  transform: scale(1.05);
}

/* Units and Chapters */
.tracker-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tracker-unit {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s;
}

.tracker-unit:hover {
  transform: translateY(-10px);
}

.tracker-unit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.tracker-unit-title {
  font-size: 1.5rem;
  color: #f1f1f1;
}

/* Progress Bar */
.tracker-progress-bar {
  background: rgba(255, 255, 255, 0.1);
  height: 10px;
  width: 50%;
  border-radius: 10px;
  overflow: hidden;
}

.tracker-progress {
  height: 100%;
  background: #f57258;
  transition: width 0.3s ease-in-out;
}

/* Chapters */
.tracker-chapters {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tracker-chapter {
  display: flex;
  align-items: center;
  gap: 10px;
}

.tracker-checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.tracker-label {
  font-size: 1rem;
  color: #f1f1f1;
  cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .tracker-header h1 {
    font-size: 2rem;
  }

  .tracker-tabs {
    flex-wrap: wrap;
    gap: 10px;
  }

  .tracker-tab {
    font-size: 0.9rem;
    padding: 8px 12px;
  }

  .tracker-unit {
    padding: 15px;
  }

  .tracker-unit-title {
    font-size: 1.3rem;
  }

  .tracker-progress-bar {
    width: 100%;
  }
}

.teacher-note {
  background: rgba(245, 114, 88, 0.1);
  border-left: 4px solid #f57258;
  border-radius: 10px;
  padding: 20px;
  margin: 0 auto 30px;
  max-width: 800px;
  display: flex;
  align-items: center;
  gap: 20px;
  transform: translateY(-10px);
  animation: float 6s ease-in-out infinite;
}

.teacher-note-icon {
  min-width: 50px;
  height: 50px;
  background: #f57258;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.teacher-note-content {
  flex: 1;
}

.teacher-note-title {
  color: #f57258;
  font-size: 1.2rem;
  margin-bottom: 8px;
  font-weight: 600;
}

.teacher-note-text {
  color: #f1f1f1;
  font-size: 1rem;
  line-height: 1.5;
}

.sparkle {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #f57258;
  opacity: 0;
  animation: sparkle 2s linear infinite;
}

@keyframes float {
  0%, 100% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(-15px);
  }
}

@keyframes sparkle {
  0% {
    transform: scale(0) rotate(0deg);
    opacity: 0;
  }
  50% {
    transform: scale(1) rotate(180deg);
    opacity: 0.8;
  }
  100% {
    transform: scale(0) rotate(360deg);
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .teacher-note {
    flex-direction: column;
    text-align: center;
    padding: 15px;
  }
}

.tracker-chapter {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: rgba(255, 255, 255, 0.05);
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.chapter-name {
  font-weight: 500;
  color: #f1f1f1;
  margin-bottom: 10px;
}

.chapter-progress {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.progress-item {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 4px 8px;
  background: rgba(245, 114, 88, 0.1);
  border-radius: 4px;
  transition: background-color 0.3s;
}

.progress-item:hover {
  background: rgba(245, 114, 88, 0.2);
}

.progress-item input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.progress-item span {
  font-size: 0.9rem;
  color: #f1f1f1;
}

@media (max-width: 768px) {
  .chapter-progress {
    flex-direction: column;
    gap: 10px;
  }
  
  .progress-item {
    width: 100%;
  }
}
