body {
    font-family: "Poppins", sans-serif;
    background-color: #0d0d0d;
    color: #fff;
    overflow-x: hidden;
  }
  

.about-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    background-color: #121212;
    color: #fff;
}

.about-title {
    font-size: 40px;
    margin-bottom: 40px;
}

.card-container {
    display: flex;
    justify-content: center;
    perspective: 1000px;
}

.card {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    padding: 20px;
    margin: 0 20px;
    width: 300px;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    transition: transform 0.5s ease, box-shadow 0.5s ease;
    cursor: pointer;
    transform-style: preserve-3d;
}

.card:hover {
    transform: translateY(-10px) rotateY(15deg) scale(1.1);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
}

.card img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 20px;
    transition: transform 0.3s ease;
}

.card:hover img {
    transform: scale(1.1);
}

.card h3 {
    font-size: 24px;
    margin-bottom: 10px;
}

.card p {
    font-size: 16px;
}

@media screen and (max-width: 768px) {
    .card-container {
        flex-direction: column;
    }

    .card {
        margin: 20px 0;
        transform: none;
    }

    .card:hover {
        transform: none;
    }
}
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 50px;
    background-color: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(20px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }

    
  nav ul {
    list-style: none;
    display: flex;
  }
  
  nav ul li {
    margin-right: 20px;
  }
  
  nav ul li a {
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    transition: color 0.3s;
  }
  
  nav ul li a:hover {
    color: #f57258;
  }
