.notes-page {
    padding: 20px;
    color: #f1f1f1;
    background-color: #121212;
    font-family: "Poppins", sans-serif;
    min-height: 100vh;
  }
  
  /* Header */
  .notes-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .notes-header h1 {
    font-size: 2.5rem;
    color: #f57258;
  }
  
  /* Tabs */
  .notes-tabs {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .notes-tab {
    background-color: #f57258;
    color: #fff;
    border: none;
    font-size: 1rem;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s, background-color 0.3s;
  }
  
  .notes-tab:hover {
    background-color: #f45a3d;
    transform: scale(1.05);
  }
  
  .notes-tab.active {
    background-color: #f45a3d;
    transform: scale(1.05);
  }
  
  /* Unit Section */
  .notes-unit {
    margin-bottom: 40px;
  }
  
  .notes-unit-title {
    font-size: 1.8rem;
    color: #f1f1f1;
    margin-bottom: 20px;
    padding-left: 20px;
  }
  
  /* Notes Grid */
  .notes-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 0 20px;
  }
  
  /* Notes Card */
  .notes-card {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 15px;
    padding: 20px;
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
  }
  
  .notes-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }
  
  .notes-card.locked {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .notes-card-header {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
  }
  
  .notes-card-header h3 {
    flex: 1;
    font-size: 1.2rem;
    color: #f1f1f1;
  }
  
  .notes-icon {
    color: #f57258;
  }
  
  .lock-icon {
    color: #f57258;
  }
  
  .notes-card-content {
    color: #ccc;
  }
  
  .notes-link {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    color: #f57258;
    text-decoration: none;
    margin-top: 10px;
    font-weight: 500;
    transition: color 0.3s;
  }
  
  .notes-link:hover {
    color: #f45a3d;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .notes-header h1 {
      font-size: 2rem;
    }
  
    .notes-tabs {
      flex-wrap: wrap;
      gap: 10px;
    }
  
    .notes-tab {
      font-size: 0.9rem;
      padding: 8px 12px;
    }
  
    .notes-grid {
      grid-template-columns: 1fr;
    }
  
    .notes-unit-title {
      font-size: 1.5rem;
      padding-left: 10px;
    }
  }