/* Base Styles */
:root {
  --color-primary: #f97316;
  --color-bg: #111827;
  --color-bg-secondary: #1f2937;
  --color-text: #ffffff;
  --color-text-secondary: #9ca3af;
  --color-border: #374151;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app {
  min-height: 100vh;
  background-color: var(--color-bg);
  color: var(--color-text);
  font-family: system-ui, -apple-system, sans-serif;
}

.container {
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 1rem;
}

/* Header */
.header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 50;
  background-color: rgba(17, 24, 39, 0.95);
  backdrop-filter: blur(4px);
  border-bottom: 1px solid var(--color-border);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.logo i {
  color: var(--color-primary);
}

.menu-toggle {
  display: none;
  padding: 0.5rem;
  background: none;
  border: none;
  color: var(--color-text);
  cursor: pointer;
  z-index: 51;
}

.nav {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.nav a, .nav button:not(.btn) {
  color: var(--color-text);
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s;
}

.nav a:hover, .nav button:not(.btn):hover {
  color: var(--color-primary);
}

/* Header Styles */
.header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 50;
  background-color: rgba(17, 24, 39, 0.95);
  backdrop-filter: blur(4px);
  border-bottom: 1px solid var(--color-border);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  position: relative;
}

.header-left {
  display: flex;
  align-items: center;
  margin-right: auto; /* Forces logo to stay left */
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--color-text);
  text-decoration: none;
}

.logo i {
  color: var(--color-primary);
}

.nav {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.nav-link {
  color: var(--color-text);
  text-decoration: none;
  transition: color 0.3s;
}

.nav-link:hover {
  color: var(--color-primary);
}

/* Update mobile menu styles */
@media (max-width: 768px) {
  .header-left {
    z-index: 52; /* Ensure logo stays above mobile menu */
  }

  .nav {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 16rem;
    background-color: var(--color-bg);
    flex-direction: column;
    padding: 6rem 2rem;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }

  .nav-open {
    transform: translateX(0);
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.2);
  }

  .menu-toggle {
    display: block;
    z-index: 51;
  }
}

/* Button Component */
.btn {
  background-color: var(--color-primary);
  color: var(--color-text);
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  border: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1rem;
}

.btn:hover {
  background-color: #ea580c;
}

/* Card Component */
.card {
  background-color: rgba(31, 41, 55, 0.5);
  border-radius: 0.5rem;
  padding: 1.5rem;
  transition: all 0.3s;
}

.card:hover {
  background-color: rgba(31, 41, 55, 0.7);
}

/* Hero Section */
.hero {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 4rem 1rem;
  background-color: black;
}

.hero-overlay {
  position: absolute;
  inset: 0;
  opacity: 0.5;
}

.hero-overlay img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-content {
  position: relative;
  z-index: 10;
  max-width: 48rem;
  margin: 0 auto;
}

.hero-content h2 {
  font-size: 4rem;
  font-weight: bold;
  letter-spacing: -0.025em;
  margin-bottom: 1rem;
}

.hero-content span {
  color: var(--color-primary);
}

.hero-content p {
  font-size: 1.5rem;
  color: var(--color-text-secondary);
  margin-bottom: 2rem;
}

.hero-btn {
  margin: 0 auto;
  padding: 1rem 2rem;
  font-size: 1.125rem;
}

/* Stats Section */
.stats {
  padding: 5rem 1rem;
  background-color: var(--color-bg-secondary);
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 2rem;
  max-width: 80rem;
  margin: 0 auto;
}

.stat-card {
  text-align: center;
}

.stats-icon {
  color: var(--color-primary);
}

.stat-content h3 {
  font-size: 1.875rem;
  font-weight: bold;
  margin: 1rem 0 0.5rem;
}

.stat-content p {
  color: var(--color-text-secondary);
}

/* Features Section */
.features {
  padding: 5rem 1rem;
  background-color: var(--color-bg);
}

.features h2 {
  font-size: 2.25rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3rem;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 2rem;
}

.feature-icon {
  color: var(--color-primary);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.feature-card h3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.feature-card p {
  color: var(--color-text-secondary);
}

/* Pricing Section */
.pricing {
  padding: 5rem 1rem;
  background-color: var(--color-bg-secondary);
}

.pricing h2 {
  font-size: 2.25rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3rem;
}

.pricing-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  max-width: 64rem;
  margin: 0 auto;
}

.pricing-card {
  display: flex;
  flex-direction: column;
}

.pricing-content {
  text-align: center;
}

.pricing-image {
  width: 5rem;
  height: 5rem;
  margin: 0 auto 1rem;
  overflow: hidden;
  border-radius: 50%;
}

.pricing-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pricing-content h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.pricing-content ul {
  list-style: none;
  margin-bottom: 2rem;
}

.pricing-content li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
}

.pricing-content li i {
  color: var(--color-primary);
}

/* Contact Section */
.contact {
  padding: 5rem 1rem;
  background-color: var(--color-bg);
}

.contact h2 {
  font-size: 2.25rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3rem;
}

.contact-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.contact-icon {
  color: var(--color-primary);
  font-size: 1.5rem;
}

.contact-item h3 {
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.contact-item p {
  color: var(--color-text-secondary);
}

.contact-form {
  max-width: 48rem;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem 1rem;
  background-color: var(--color-bg-secondary);
  border: none;
  border-radius: 0.5rem;
  color: var(--color-text);
  outline: none;
  transition: all 0.3s;
}

.form-group input:focus,
.form-group textarea:focus {
  box-shadow: 0 0 0 2px var(--color-primary);
}

.submit-btn {
  width: 100%;
}

/* Footer */
.footer {
  background-color: var(--color-bg);
  border-top: 1px solid var(--color-border);
  padding: 2rem 1rem;
  text-align: center;
}

.footer p {
  color: var(--color-text-secondary);
  margin-bottom: 1rem;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.footer-links a {
  color: var(--color-primary);
  text-decoration: none;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: #ea580c;
}

.footer-links span {
  color: var(--color-border);
}

/* Responsive Design */
@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .nav {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 16rem;
    background-color: var(--color-bg);
    flex-direction: column;
    padding: 6rem 2rem;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }

  .nav-open {
    transform: translateX(0);
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.2);
  }

  .hero-content h2 {
    font-size: 3rem;
  }

  .hero-content p {
    font-size: 1.25rem;
  }
}

@media (max-width: 480px) {
  .hero-content h2 {
    font-size: 2.5rem;
  }

  .stat-content h3 {
    font-size: 1.5rem;
  }
}

/* Update the logo styles in your CSS file */
.logo {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  text-decoration: none;
  margin-right: auto; /* Pushes everything else to the right */
  padding-left: 0; /* Ensures logo starts from the very left */
}

.logo-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
}

.logo-text {
  color: var(--color-text);
  font-size: 1.5rem;
  font-weight: bold;
  white-space: nowrap;
}

.header-left {
  display: flex;
  align-items: center;
  flex: 1; /* Takes up available space */
}

/* Update header content to ensure proper spacing */
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  padding: 0 1rem;
}

/* Ensure responsive design works with new logo */
@media (max-width: 768px) {
  .logo-text {
    font-size: 1.25rem; /* Slightly smaller on mobile */
  }
  
  .logo-image {
    width: 32px; /* Slightly smaller on mobile */
    height: 32px;
  }
}